import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "layouts";
// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
// import Success from "layouts/success";
import theme from "theme/theme";

export default function Main() {
  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <Routes>
          <Route path={`/`} element={<AdminLayout />} />
          {/* <Route path={`/success-message`}>
            <Success />
          </Route>
          <Route path={`/success-message-ai`}>
            <Success />
          </Route>
          <Route path={`/`}>
            <AdminLayout />
          </Route> */}
        </Routes>
      </React.StrictMode>
    </ChakraProvider>
  );
}
